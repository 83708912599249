.indexing {
    border-left: 1px solid #eee;
    font-size: 0.9em;
    list-style: none;
    padding: 15px;
    position: sticky;
    top: 90px;
    min-width: 160px;
}

.indexing li {
    padding: 4px;
}

.indexing a {
    color: #000;
    border-left: 4px solid transparent;
    padding-left: 10px;
}

.indexing a.selected {
    font-weight: bold;
    border-left-color: var(--button-color);
}

.indexing ul {
    list-style: none;
    padding: 0;
    margin: 0 0 15px 15px;
}

.indexing:empty {
    display: none;
}

@media (max-width: 1200px) {
    .indexing {
        display: none;
    }
}